.remove-btn {
    margin-right: 0.5rem;
}

.fade-enter {
    opacity: 0;
    -webkit-transition: opacity 500ms ease-in;
       -moz-transition: opacity 500ms ease-in;
        -ms-transition: opacity 500ms ease-in;
         -o-transition: opacity 500ms ease-in;
            transition: opacity 500ms ease-in;
}

.fade-enter-active {
    opacity: 1;
}

.btn-hopscotch.btn-secondary {
    background-color: #a32273; 
    border-color: #a32273
}

.btn-hopscotch.btn-secondary:hover {
    background-color: #8f1061; 
    border-color: #840e5e
}

.btn-hopscotch.btn-secondary:not(:disabled):not(.disabled).active, 
.btn-hopscotch.btn-secondary:not(:disabled):not(.disabled):active {
    color: #ffff; 
    background-color: #8f1061; 
    border-color: #840e5e
}

.btn-hopscotch.btn-secondary.dropdown-toggle {
    background-color: #8f1061; 
    border-color: #840e5e
}

.btn-hopscotch.btn-secondary:not(:disabled):not(.disabled).active:focus, 
.btn-hopscotch.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-hopscotch.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
}

.btn-hopscotch.btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
}

.btn-hopscotch.btn-outline-secondary {
    color: #a32273; 
    border-color: #a32273
}

.btn-hopscotch.btn-outline-secondary:hover {
    color: #ffff; 
    background-color: #a32273; 
    border-color: #a32273
}

.btn-hopscotch.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-hopscotch.btn-outline-secondary:not(:disabled):not(.disabled):active {
    color: #ffff; 
    background-color: #a32273; 
    border-color: #a32273
}

.btn-hopscotch.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
}

.filter-hopscotch.filter.text-filter:focus,
.filter-hopscotch.filter.select-filter:focus,
.multi-select-hopscotch.filter.select-filter:focus {
    border-color: #a32273;
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
}


/*.multi-select-hopscotch.filter.select-filter:focus option:checked,
.multi-select-hopscotch.filter.select-filter option:checked {
    background: #a32273 linear-gradient(0deg, #a32273 0%, #a32273 100%);
}*/

.multi-select-hopscotch.filter.select-filter option {
    height: 1.45rem;
}
.multi-select-hopscotch.filter.select-filter {
    padding: 0;
    text-align: center;
    background-color: white;
    height: 4.5rem;
}

#data_table thead th { 
    position: sticky; 
    top: 55px;
    background-color: #ffff; 
    z-index: 1000;
    /*font-size: 0.8rem;*/
}

.react_bootstrap_table {
    margin-left: 10px;
    margin-right: 10px;
}

#data_table tbody tr {
    /*font-size: 0.8rem;*/
}
.custom-control-label:before, .custom-file-label, .custom-select{
    border-color: #a32273;
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
  }

  .custom-checkbox .custom-control-input:not(:checked)~.custom-control-label::before,
  .custom-radio .custom-control-input:not(:checked)~.custom-control-label::before {
    border-color: #a32273;
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
  .custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color:#a32273;
    border-color: #a32273;
    box-shadow: 0 0 0 0.2rem rgba(168, 64, 143, .5); 
  }

  .historyTable.table thead th {
    border-top: none;
  }

  .popover-hopscotch .popover {
    max-width: 500px;
  }

  .popover-body {
    height: 400px;
    overflow-y: auto;
    white-space:pre-wrap;
}

.popover thead th { 
    position: sticky; 
    top: -10px;
    background-color: #ffff; 
    margin-top: 0px;
    /*z-index: 1000;*/
}

.react-datepicker__input-container input {
    border-radius: 0.25rem;
    border-color: #a32273;
    border-width: 1px;
    text-align: center;
}

.react-datepicker__input-container input:focus {
    outline: none;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
}





